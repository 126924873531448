import axios from 'axios'
import config from './config'

export const AUTHENTIC_SERVER = process.env.REACT_APP_AUTHENTIC_SERVER ||
'https://auth.aiinkwell.app'

const getApiServer = () => {
  return config.apiServer || process.env.REACT_APP_API_SERVER || 'https://revere.aiinkwell.app'
}

const headers = () => {
  const tokenKey = '_revere_authToken'
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem(tokenKey)}`
  }
}

export const getSetupInstructions = async () => {
  try {
    const response = await axios.get(
      `${getApiServer()}/setup-instructions`,
      { headers: headers() }
    )
    return response.data
  } catch (error) {
    console.error('Error fetching setup instructions:', error)
    throw error
  }
}

export const getChannels = async () => {
  try {
    const response = await axios.get(
      `${getApiServer()}/api/slack/channels`,
      { headers: headers() }
    )
    return response.data
  } catch (error) {
    console.error('Error fetching channels:', error)
    throw error
  }
}

export const updateSubscriptions = async (data) => {
  try {
    const response = await axios.put(
      `${getApiServer()}/api/slack/subscriptions`,
      data,
      { headers: headers() }
    )
    return response.data
  } catch (error) {
    console.error('Error updating subscriptions:', error)
    throw error
  }
}

export const getChannelSummary = async (channelId, from, to) => {
  try {
    const response = await axios.get(
      `${getApiServer()}/api/slack/summaries/${channelId}?from=${from}&to=${to}`,
      { headers: headers() }
    )
    return response.data
  } catch (error) {
    console.error('Error fetching channel summary:', error)
    throw error
  }
}

export const getMetrics = async (opts = {}) => {
  const params = new URLSearchParams(opts)
  try {
    const response = await axios.get(
      `${getApiServer()}/api/slack/metrics?${params}`,
      { headers: headers() }
    )
    return response.data
  } catch (error) {
    console.error('Error fetching metrics:', error)
    throw error
  }
}
