import ArticleIcon from '@mui/icons-material/Article'
import TagIcon from '@mui/icons-material/Tag'
import AnalyticsIcon from '@mui/icons-material/Analytics'

const sidebarItems = [
  {
    id: 'channels',
    text: 'Channels',
    icon: <TagIcon />,
    path: ''
  },
  {
    id: 'documentation',
    text: 'Documentation',
    icon: <ArticleIcon />,
    path: 'documentation'
  },
  {
    id: 'analytics',
    text: 'Analytics',
    icon: <AnalyticsIcon />,
    path: 'analytics'
  }
]

export default sidebarItems
