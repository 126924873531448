import * as React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import { Link, useMatches } from 'react-router-dom'
import sidebarItems from '../data/sidebar'

export default function MenuContent () {
  const match = useMatches()

  const prefix = (match[1]?.pathname || '').replace(/^\/$/, '')
  const activePath = match[2]?.pathname?.replace(prefix, '')

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {sidebarItems.map((item, index) => (
          <NavItem
            key={item.path}
            path={`${prefix}/${item.path}`}
            isActive={activePath === `/${item.path}`}
            text={item.text}
            icon={item.icon}
          />
        ))}
      </List>
    </Stack>
  )
}

function NavItem ({ path, isActive, text, icon }) {
  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        selected={isActive}
        LinkComponent={Link}
        to={path}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  )
}
